.side-card {
    max-width: 400px;
    /* min-width: 325px; */
    min-height: 150px;
    padding: 0px 20px;
  }

  .preloader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    background-color: #ffffff;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../assets/preloader.gif);
  }
  
  .towerBlackPng {
    width: 1200px;
    right: -480px;
  }
  .towerExpPart {
    min-height: 830px;
  }
  .mapper-card {
    max-width: 25%;
    min-height: 100px;
  }
  .side-card-container {
    justify-content: space-around;
  }

  .navbar {
    position: fixed;
    background-color: white;
    top: 0px;
    right: 0px;
    left: 0px;
    transition: all 0.3 ease-in-out;
    z-index: 1000;
    
  }

  .navbar-mobile {
    position: fixed;
    /* background-color: ; */
    top: 0px;
    right: 0px;
    left: 0px;
    transition: all 0.3 ease-in-out;
    z-index: 1000;
    
  }

  .sub-menu {
    display: none;
    position: absolute;
    top: 32;
    background-color: white;
    border: 1px solid #ccc;
    border-color: #EAECF0;
    border-radius: 8px;
    z-index: 10;
    width: 220px; 
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000)
  }
  
  .dropdown:hover .sub-menu {
    display: block;
  }
  
  .sub-dropdown {
    position: relative;
   
  }
  
  .sub-sub-menu {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 1;
    width: 280px; 
    background-color: white;
    border: 1px solid #ccc;
    border-color: #EAECF0;
    
    border-radius: 8px;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000)
  }
  
  .sub-dropdown:hover .sub-sub-menu {
    display: block;
  }
  

  .menu-item:hover + .sub-menu {
    display: block;
  }
  @media only screen and (max-width: 1380px) {
    .mapper-card {
      max-width: 30%;
      min-height: 100px;
    }
  }
  
  @media only screen and (max-width: 1150px) {
    .main-products {
      flex-direction: column;
      gap: 50px;
    }
    .side-card {
      max-width: 425px;
      min-width: none;
    }
  }
  @media only screen and (max-width: 700px) {
    .side-card-container {
      flex-direction: column;
      gap: 20px;
    }
    .side-card {
      max-width: 500px;
      min-width: none;
    }
  }
  @media only screen and (max-width: 500px) {
    .tower-png {
      min-width: 355px;
      max-width: 355px;
    }
    .towerBlackPng {
      width: 1150px;
      right: -500px;
    }
  }
  